// import logo from './logo.png';
import './App.css';
import CenterText from './components/CenterTextComponent'; // Adjust the path as needed

const App = () => {
  return (
    <div className="App">
      <CenterText />
    </div>
  );
}

export default App;
