import React from 'react';
import './CenterText.css'; // Ensure to create this CSS file with the provided styles

const CenterText = () => {
    return (
        <div className="text-container">
            <h1 className="sophisticated">Dimitar Musev</h1>
        </div>
    );
};

export default CenterText;
